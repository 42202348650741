<template>
	<div class="List">
		<van-nav-bar
			title="案件列表"
			fixed
			placeholder
			:z-index="10"
			left-arrow
			@click-left="onClickLeft"
		>
			<template #right>
				<span @click="onSerachClick">查询</span>
			</template>
		</van-nav-bar>
		<van-tabs
			v-if="['0', '1'].includes(queryoOrderStatus)"
			v-model="filter.orderStatus"
			@change="handelTab"
			title-active-color="#3C86FF"
			title-inactive-color="#41444E"
		>
			<van-tab title="待派单" name="0"></van-tab>
			<van-tab title="已派单" name="1"></van-tab>
		</van-tabs>
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-list
				v-model="loading"
				:finished="finished"
				finished-text="没有更多了"
				@load="onLoad"
				class="list-box"
			>
				<div class="list-item" v-for="(item, index) in list" :key="index">
					<van-checkbox
						v-if="queryoOrderStatus != '2'"
						class="checkbox"
						v-model="item.checked"
						@change="onItemChange"
						shape="round"
					/>
					<CaseCard
						@click.native="onItemClick(item)"
						:item="item"
						:orderStatus="filter.orderStatus"
						class="item"
						@actionClick="onActionClick"
					>
					</CaseCard>
				</div>
			</van-list>
		</van-pull-refresh>
		<SubmitBar
			v-if="queryoOrderStatus != '2'"
			:checked="checkedAll"
			:title="filter.orderStatus == '0' ? '派单' : '改派'"
			@change="onChange"
			@submit="onSubmit"
		></SubmitBar>
		<PopupForm
			v-model="showPopup"
			:actionType="actionType"
			:actionItem="actionItem"
			@confirmPopup="confirmPopup"
		>
		</PopupForm>
	</div>
</template>

<script>
import { Search } from "vant";
import { Icon } from "vant";
import { Tabbar, TabbarItem } from "vant";
import Vue from "vue";
Vue.use(Search, Icon);
Vue.use(Tabbar);
Vue.use(TabbarItem);
import Api from "@/api/caseHandle";
import popupFormMixin from "@/mixins/popupFormMixin";
// const orderStatusEnum = {
//   "0": "ACCEPTING",
//   "1": "ACCEPTING,PROCESSING",
//   "2": "ACCEPTING",
// };
const orderStatusEnum = {
	"0": ["ASSIGNING"],
	"1": ["ACCEPTING", "PROCESSING"],
	"2": ["ACCEPTING"]
};
import { Toast } from "vant";
export default {
	mixins: [popupFormMixin],
	props: {},
	components: {
		SubmitBar: () => import("./components/SubmitBar")
	},
	data() {
		return {
			list: [],
			loading: false,
			finished: false,
			checkedAll: false,
			refreshing: false,

			filter: {
				orderStatus: this.$route.query.orderStatus || "0"
			},
			pageNo: 1,
			pageSize: 10
		};
	},
	computed: {
		queryoOrderStatus() {
			return this.$route.query.orderStatus;
		}
	},
	methods: {
		batchProcessing() {
			let checkedHomeNos = this.list
				.filter((item) => item.checked)
				.map((item) => item.homeNo);
			if (this.actionType == "批量改派") {
				this.changeClick({}, checkedHomeNos);
			} else {
				this.distributeClick({}, checkedHomeNos);
			}
		},
		onItemClick(item) {
			item.checked = !item.checked;
		},
		onChange(v) {
			this.list.forEach((element) => {
				element.checked = v;
			});
			this.checkedAll = v;
		},
		onItemChange() {
			let checked = this.list.filter((item) => item.checked);
			if (checked.length <= 0) {
				this.checkedAll = false;
			}
			if (checked.length == this.list.length) {
				this.checkedAll = true;
			}
		},
		onSubmit() {
			let checked = this.list.filter((item) => item.checked);
			if (checked.length <= 0) {
				return Toast.fail("请选择要处理的数据");
			}
			this.actionItem = {};
			this.actionType =
				this.filter.orderStatus == "0" ? "批量派单" : "批量改派";
			this.showPopup = true;
		},
		handelTab() {
			this.checkedAll = false;
			const parameterUrl = this.$router.resolve({
				name: this.$route.name,
				query: {
					...this.filter
				}
			}).href;
			history.replaceState(null, null, parameterUrl);
			this.onRefresh();
		},
		onSearch() {
			this.pageNo = 1;
			this.list = [];
			this.onLoad();
		},
		onSerachClick() {
			this.$router.push({
				name: "searchCase",
				query: { orderStatus: this.filter.orderStatus }
			});
		},
		async onLoad() {
			try {
				this.finished = false;
				this.loading = true;
				const { pageNo, pageSize, filter } = this;
				const params = {
					pageNo,
					pageSize,
					...filter,
					// statusEq: orderStatusEnum[filter.orderStatus]
					statusIn: orderStatusEnum[filter.orderStatus]
				};

				var res = await Api.findPageISO(params);
				const { data } = res;
				const { list } = data;
				list.forEach((item) => {
					item.checked = false;
				});
				if (this.pageNo == 1) {
					this.list = list;
				} else {
					this.list = [...this.list, ...list];
				}
				this.finished = !data.isNextPage;
				this.pageNo++;
			} catch (error) {
				Toast.fail(error);
				this.list = [];
				this.finished = true;
			} finally {
				this.loading = false;
				this.refreshing = false;
			}
		},
		onRefresh() {
			this.pageNo = 1;
			this.checkedAll = false;
			this.onLoad();
		}
	},
	mounted() {
		this.fetchEnums("getHomeStatusEnum");
	}
};
</script>

<style lang="less" scoped>
.List {
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.van-tabs {
	/deep/ .van-tabs__line {
		background: #3c86ff !important;
		height: 2px !important;
		width: 4em !important;
	}
}

.list-box {
	padding: 10px 12px;
	padding-bottom: 70px;
	.list-item {
		position: relative;
		.checkbox {
			position: absolute;
			right: 17px;
			top: 18px;
			z-index: 3;
			/deep/ .van-checkbox__icon .van-icon {
				border-width: 2px;
			}
		}
	}
	.item {
		margin-bottom: 15px;
	}
}
</style>
